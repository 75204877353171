import React from 'react';
import { useRouter } from 'next/router';
//styles
import classNames from 'classnames';
import styles from './footer.module.scss';
//Images
import Image from 'next/image';
import Logowhite from '../../../../../public/images/Logowhite.png';
import apple from '../../../../../public/landingimages/newapple.webp';
import playstore from '../../../../../public/landingimages/mobilegoogleplay.webp';

const Footer = () => {

    const router = useRouter();

    const homePage = () =>{
        router.push("/")
    }
    
    const handlePlaystore = ()=>{
        // router.push('https://play.google.com/store/apps/details?id=com.turftown&hl=en_US&pli=1')
        window.open('https://play.google.com/store/apps/details?id=com.turftown', '_blank')
    }
    const handleApplestore = ()=>{
        window.open('https://apps.apple.com/in/app/turf-town/id1490231308', '_blank')
    }

    const handleContactUs = () =>{
        router.push('/contact')
    }

    const handleTermsofService = () =>{
        router.push('/termsofservice')
    }

    const handlePrivacy = () =>{
        router.push('/privacy-policy')
    }
    const handleInstagram = () =>{
        window.open('https://www.instagram.com/turftown.in/?hl=en', '_blank')
    }
    const handleTwitter = () =>{
        window.open('https://twitter.com/TurfTownOffl', '_blank')
    }
    const handleLinkedIn = () =>{
        window.open('https://www.linkedin.com/company/turf-town/', '_blank')
    }
   
    return (
        <>
            <div className={classNames(styles.footer_background)}>
                <div className={classNames(styles['mt-60'])}>
                    <Image src={Logowhite} alt="" className={styles.Footer_turf_logo} onClick={homePage}/>
                </div>
                <div className={classNames(styles.footer_title, styles['mt-54'])}>
                    <div>
                        <div className={classNames(styles.title_footer)}>Company</div>
                        <div className={classNames(styles.footer_content, styles['mt-24'])} onClick={handleContactUs}>Contact us</div>
                        <div className={classNames(styles.footer_content, styles['mt-24'])} onClick={handleTermsofService}>Terms of Service</div>
                        <div className={classNames(styles.footer_content, styles['mt-24'])} onClick={handlePrivacy}>Privacy Policy</div>

                    </div>
                    <div>
                        <div className={classNames(styles.title_footer)}>Social</div>
                        <div className={classNames(styles.footer_content, styles['mt-24'])} onClick={handleInstagram}>Instagram</div>
                        <div className={classNames(styles.footer_content, styles['mt-24'])} onClick={handleTwitter}>Twitter</div>
                        <div className={classNames(styles.footer_content, styles['mt-24'])} onClick={handleLinkedIn}>LinkedIn</div>
                    </div>
                </div>
                <div className={classNames(styles.playstores_icons, styles['mt-80'])}
                style={{display:"flex",gap:"15px"}}>
                        <Image src={apple} alt=""
                        className={classNames(styles.Appstore_icon)}  onClick={handlePlaystore} />
                        <Image src={playstore} alt="" 
                        className={classNames(styles.Playstore_icon)} onClick={handleApplestore} />         
                </div>
                <div className={classNames(styles.terms_condition)}>Designed for sport & made in Madras</div>
            </div>
        </>
    )
}

export default Footer