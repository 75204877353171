import React, { useState } from 'react';
import { useRouter } from 'next/router';
//Image
import Image from 'next/image';
import Logo from '@/components/common/logo/logo';
import menuIcon from '../../../../../public/icons/menu_icon.png';
import closeIcon from '../../../../../public/landingimages/close_icon.png';
import getTurfTown from '../../../../../public/landingimages/getturftownbutton.svg';
//styles
import classNames from 'classnames';
import styles from './navbar.module.scss';
import {motion} from 'framer-motion';
//device
import {
  isAndroid,
  isIOS
} from "react-device-detect";

const Navbar = () => {

  const [showMenu, setShowMenu] = useState<boolean>(false);
  const router = useRouter();

  const openMenu = () => {
    setShowMenu(true);
  };

  const closeMenu = () => {
    setTimeout(() => {
      setShowMenu(false);
    }, 50);
  };

  const goToExploreVenue = () =>{
    router.push('/locate-venues');
    setShowMenu(false);
  }
  const goToBlog = () =>{
    router.push('/blog');
    setShowMenu(false);
  }
  const goToContact = () =>{
    router.push('/contact');
    setShowMenu(false);
  }

  const handleDownload = () => {
    if (isAndroid) {
        window.location.href =
            "https://play.google.com/store/apps/details?id=com.turftown&hl=en_US";
    } else if (isIOS) {
        window.location.href =
            "https://onelink.to/turftown";
    } else {
        window.location.href =
            "https://turftown.in";
    }
}

  return (
    <>
      <motion.div animate={{ y: 0, opacity: 1 }} initial={{ y: -70, opacity: 0 }}
        transition={{ type: 'spring', damping: 34, stiffness: 230, mass: 2 }} className={classNames(styles["navbar_container"])}>
        <Logo />
        <Image src={menuIcon} alt='menuIcon' height={30} width={30}
          className={classNames(styles["cursor-pointer"],styles.menuIcon_bar)} onClick={openMenu} />
      </motion.div>

      {showMenu && (
        <motion.div animate={{ x: 0, opacity: 1 } } initial={{ x: -70, opacity: 0 }}
          transition={{ type: 'spring', damping: 34, stiffness: 230, mass: 2 }} className={styles.menuModal}>
          <div className={styles.menuBar_left}>
            <Logo />
            <div className={styles.menuList}>
              <div onClick={goToExploreVenue}>Explore Venues</div>
              <div onClick={goToBlog}>Blog</div>
              <div onClick={goToContact}>Contact</div>
              <Image src={getTurfTown} alt='get turftown'
                height={40} width={176} onClick={handleDownload}/>
            </div>
          </div>
          <div className={styles.menuBar_right}>
            <Image src={closeIcon} alt="close" className={styles.closeMenuIcon} onClick={closeMenu}
              height={18} width={18} />
          </div>
        </motion.div>
      )}
    </>
  )
}

export default Navbar;